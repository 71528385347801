<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 offset-lg-2">
                  <div class="breadcrumb__wrapper">
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0">
                           <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">Trabalhe conosco</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>

		<!-- formulario area start -->
      <section class="formulario__area pt-20 pb-200 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 offset-lg-2 container-formulario">
                  <div class="row m-0">
                     <div class="col-12 px-1">
                        <div class="section__head mb-30">
                           <div class="w-100 section__title mb-1">
                              <h3 class="text-center">Trabalhe conosco</h3>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 px-1">
                        <logoMarcas :classes="'brand__logo__area pt-10 pb-30'" :view="'TrabalheConosco'" />
                     </div>
                     <div class="col-12 px-1 mb-30">
                        <p class="text-center" v-html="sourceTrabalheConosco.descricao"></p>
                     </div>
                     <div class="col-12 px-1 mb-10">
                        <label>Nome *</label>
                        <input type="text" class="form-control" v-model="formulario.nome" maxlength="200" />
                     </div>
                     <div class="col-12 px-1 mb-10">
                        <label>Email *</label>
                        <input type="email" class="form-control" v-model="formulario.email" maxlength="200" />
                     </div>
                     <div class="col-12 px-1 mb-10">
                        <label>Telefone (Com DDD) *</label>
                        <input type="text" class="form-control" v-model="formulario.telefone" maxlength="200" />
                     </div>
                     <div class="col-12 px-1 mb-10">
                        <label>Endereço *</label>
                        <input type="text" class="form-control" v-model="formulario.endereco.rua" maxlength="100" />
                     </div>
                     <div class="col-7 px-1 mb-10">
                        <label>Bairro</label>
                        <input type="text" class="form-control" v-model="formulario.endereco.bairro" maxlength="50" />
                     </div>
                     <div class="col-5 px-1 mb-10">
                        <label>CEP *</label>
                        <input type="text" class="form-control" v-model="formulario.endereco.cep" maxlength="10" />
                     </div>
                     <div class="col-7 px-1 mb-10">
                        <label>Cidade *</label>
                        <input type="text" class="form-control" v-model="formulario.endereco.cidade" maxlength="100" />
                     </div>
                     <div class="col-5 px-1 mb-10">
                        <label>UF *</label>
                        <input type="text" class="form-control" v-model="formulario.endereco.estado" maxlength="2" />
                     </div>
                     <div class="col-12 px-1 mb-10">
                        <label>O que você pode fazer pela CCM do Brasil? *</label>
                        <textarea type="text" class="form-control" v-model="formulario.qualificacoes" maxlength="200"></textarea>
                     </div>
                     <div class="col-6 px-1 mb-10">
                        <label>Área pretendida *</label>
                        <input type="email" class="form-control" v-model="formulario.area" maxlength="200" />
                     </div>
                     <div class="col-6 px-1 mb-20">
                        <label>Carregar currículo</label>
                        <input type="file" class="form-control" ref="curruculoAnexo" accept=".doc,.docx,.pdf" />
                     </div>
                     <div class="col-12 px-1 text-center">
                        <button class="t-y-btn t-y-btn-2" @click="cadastrar">Enviar formulário <i class="fas fa-chevron-right font-12 ms-2"></i></button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import router from '@/router'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import logoMarcas from '@/components/CarouselLogoMarcas.vue'

export default {
	name: 'Trabalhe_Conosco',
   data: function() {
		return {
			formulario: {'nome': '', 'email': '', 'telefone': '', 'qualificacoes': '', 'area': '', 
            'endereco': {'rua': '', 'bairro': '', 'cep': '', 'cidade': '', 'estado': ''}}
		}
	},
   computed: {
		... mapState({
         sourceTrabalheConosco: state => state.source.trabalheConosco,
			urlRest: state => state.urlRest
		})
	},
   components: {
      logoMarcas
   },
   methods: {
      cadastrar : function () {
         if (this.formulario.nome == null || String(this.formulario.nome).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Nome inválido!'
            });

            return
         } else if (this.formulario.email == null || String(this.formulario.email).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Email inválido!'
            });

            return
         } else if (this.formulario.telefone == null || String(this.formulario.telefone).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Telefone inválido!'
            });

            return
         } else if (this.formulario.endereco.rua == null || String(this.formulario.endereco.rua).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Rua inválida!'
            });

            return
         } else if (this.formulario.endereco.cidade == null || String(this.formulario.endereco.cidade).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Cidade inválida!'
            });

            return
         } else if (this.formulario.endereco.estado == null || String(this.formulario.endereco.estado).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Estado inválido!'
            });

            return
         } else if (this.formulario.endereco.cep == null || String(this.formulario.endereco.cep).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'CEP inválido!'
            });

            return
         } else if (this.formulario.area == null || String(this.formulario.area).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Área pretendida inválida!'
            });

            return
         } else if (this.formulario.qualificacoes == null || String(this.formulario.qualificacoes).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Qualificações inválidas!'
            });

            return
         }

         let dataForm = new FormData();
         this.$store.dispatch('alternarTelaCarregamento', true)

         for (let file of this.$refs.curruculoAnexo.files) {
            dataForm.append('file', file, String(file.name).replace(/[^\w\s.]/gi, ''));
         }
         
         dataForm.append('info', JSON.stringify(this.formulario));

         this.$axios({
            method: 'post',
            url: this.urlRest +'site/cadastrarCandidato',
            headers: {
					'Content-Type': 'multipart/form-data'
            },
				data: dataForm

         }).then(() => {
            this.formulario = {'nome': '', 'email': '', 'telefone': '', 'qualificacoes': '', 'area': '', 'anexo': null, 
               'endereco': {'rua': '', 'bairro': '', 'cep': '', 'cidade': '', 'estado': ''}}

            Swal.fire({
               icon: 'success',
               title: 'Formulário enviado!'

            }).then(() => {
               router.push('/')
            });
         }).catch((error) => {
            if (error.response != undefined) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      }
   }
}

</script>